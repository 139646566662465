import React from 'react'
import { HeaderMenuProps } from './type'
import { FilterBackIcon } from '@core/components/icon'
import {
  AddBusinessOutlined,
  BuildOutlined,
  FactCheckOutlined,
  FactoryOutlined,
  ManageAccountsOutlined,
  PlagiarismOutlined,
  PrecisionManufacturingOutlined,
} from '@mui/icons-material'
import PostAddIcon from '@mui/icons-material/PostAdd';
import { RouterLink, SettingRouterLink } from '@core/enums/router.enum'
import { AuthorizationEnum } from '@core/enums/authorization.enum'

const menuList: HeaderMenuProps = {
  dashboard: {
    label: 'dashboard',
    href: RouterLink.HOME,
    permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER]
  },
  equipment: {
    label: 'equipment',
    permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER],
    menus: [
      {
        label: 'equipment-manage',
        href: RouterLink.EQUIPMENT_MANAGE,
        icon: <PrecisionManufacturingOutlined />,
        permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER]
      }
    ]
  },
  inspect: {
    label: 'inspect',
    permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER],
    menus: [
      {
        label: 'inspect-plan',
        href: RouterLink.INSPECT_PLAN,
        icon: <FactCheckOutlined />,
        permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER]
      },
      {
        label: 'inspect-result',
        href: RouterLink.INSPECT_RESULT,
        icon: <PlagiarismOutlined />,
        permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER]
      }
    ]
  },
  repair: {
    label: 'repair',
    permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER],
    menus: [
      {
        label: 'manage-repair',
        href: RouterLink.MANAGE_REPAIR_PLAN,
        icon: <BuildOutlined />,
        permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER]
      }
    ]
  },
  filter: {
    label: 'filter',
    permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER],
    menus: [
      {
        label: 'filter-manage',
        href: RouterLink.FILTER_MANAGE,
        icon: <FilterBackIcon />,
        permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER]
      }
    ]
  },
  setting: {
    label: 'setting',
    permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER],
    menus: [
      {
        label: 'setting-account',
        href: SettingRouterLink.ACCOUNT,
        icon: <ManageAccountsOutlined />,
        permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER]
      },
      {
        label: 'setting-company',
        href: SettingRouterLink.COMPANY,
        icon: <AddBusinessOutlined />,
        permissions: [AuthorizationEnum.ADMIN]
      },
      {
        label: 'setting-factory',
        href: SettingRouterLink.FACTORY,
        icon: <FactoryOutlined />,
        permissions: [AuthorizationEnum.ADMIN]
      },
      {
        label: 'setting-inspect-custom',
        href: SettingRouterLink.INSPECT_CUSTOM,
        icon: <PostAddIcon />,
        permissions: [AuthorizationEnum.ADMIN]
      }
    ]
  }
  // TODO : IEMS 연동시 활성화
  // service: {
  //   label: 'service',
  //   permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER],
  //   menus: [
  //     {
  //       label: 'service-sync',
  //       href: RouterLink.SERVICE_SYNC,
  //       icon: <AnalyticsOutlined />,
  //       permissions: [AuthorizationEnum.ADMIN, AuthorizationEnum.USER]
  //     }
  //   ]
  // }
}

export default menuList
