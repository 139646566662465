import React, { lazy, Suspense } from 'react';
import { RouterModel } from '@core/components/navigator/type';
import PrimaryLayout from '@theme/layouts/primary';
import AuthLayout from '@theme/layouts/auth';
import ProtectedRoute from '@core/components/protected-route';
import { AuthRouterLink, OptionLink, RouterLink, SettingRouterLink } from '@core/enums/router.enum';
import NotFound from '@pages/error/not-found';

const Dashboard = lazy(() => import('@pages/dashboard'));
const EquipmentManage = lazy(() => import('@pages/equipment').then(module => ({ default: module.EquipmentManage })));
const InspectPlanManagement = lazy(() =>
  import('@pages/inspect/plan-management').then(
    (module) => ({ default: module.InspectPlanManagement })
  )
);
const InspectResult = lazy(() => import('@pages/inspect/result').then(module => ({ default: module.InspectResult })));
const FilterBagHistory = lazy(() => import('@pages/filter').then(module => ({ default: module.FilterBagHistory })));
const RepairPlanManagement = lazy(() => import('@pages/repair/plan-list'));
const SettingsLayout = lazy(() => import('@pages/settings'));
const AccountSetting = lazy(() => import('@pages/settings/account'));
const SettingCompany = lazy(() => import('@pages/settings/company'));
const SettingFactory = lazy(() => import('@pages/settings/factory'));
const InspectCustom = lazy(
  () => import('@pages/settings/inspect-custom/InspectCustom')
);
const ServiceSync = lazy(() => import('@pages/service').then(module => ({ default: module.ServiceSync })));const MeasurementChart = lazy(() => import('@pages/inspect/plan-method/measurement-chart/MeasurementChart'));
const Login = lazy(() => import('@pages/login'));

/**
 * Router configs component
 */
const routerConfigs = (): RouterModel[] => {
  return [
    {
      element: (
        <ProtectedRoute>
          <PrimaryLayout />
        </ProtectedRoute>
      ),
      path: RouterLink.BASE_PATH,
      children: [
        {
          path: RouterLink.HOME,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <Dashboard />
            </Suspense>
          )
        },
        {
          path: RouterLink.EQUIPMENT_MANAGE,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <EquipmentManage />
            </Suspense>
          )
        },
        {
          path: RouterLink.INSPECT_PLAN,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <InspectPlanManagement />
            </Suspense>
          )
        },
        {
          path: RouterLink.INSPECT_RESULT,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <InspectResult />
            </Suspense>
          )
        },
        {
          path: RouterLink.FILTER_MANAGE,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <FilterBagHistory />
            </Suspense>
          )
        },
        {
          path: RouterLink.MANAGE_REPAIR_PLAN,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <RepairPlanManagement />
            </Suspense>
          )
        },
        {
          path: SettingRouterLink.BASE_PATH,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <SettingsLayout />
            </Suspense>
          ),
          children: [
            {
              path: SettingRouterLink.ACCOUNT,
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <AccountSetting />
                </Suspense>
              )
            },
            {
              path: SettingRouterLink.COMPANY,
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <SettingCompany />
                </Suspense>
              )
            },
            {
              path: SettingRouterLink.FACTORY,
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <SettingFactory />
                </Suspense>
              )
            },
            {
              path: SettingRouterLink.INSPECT_CUSTOM,
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <InspectCustom />
                </Suspense>
              )
            }
          ]
        },
        {
          path: RouterLink.SERVICE_SYNC,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <ServiceSync />
            </Suspense>
          )
        }
      ]
    },
    {
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthLayout />
        </Suspense>
      ),
      path: AuthRouterLink.BASE_PATH,
      children: [
        {
          path: AuthRouterLink.LOGIN,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <Login />
            </Suspense>
          )
        }
      ]
    },
    {
      path: OptionLink.BASE_PATH,
      children: [
        {
          path: OptionLink.MEASUREMENT_CHART,
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <MeasurementChart />
            </Suspense>
          )
        }
      ]
    },
    {
      path: '*',
      element: (
          <Suspense fallback={<div>Loading...</div>}>
            <NotFound />
          </Suspense>
        ),
      children: []
    }
  ];
};

export { routerConfigs };
